@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

.b-resource-avatar {
  // -ms-touch-action: pan-x pan-y;
  // touch-action: pan-x pan-y;
  // display: -ms-flexbox;
  // display: flex;
  // -ms-flex-align: center;
  // align-items: center;
  // -ms-flex-pack: center;
  // justify-content: center;

  // height: 2.2em;
  border-radius: 0%;
  // -ms-flex-negative: 0;
  // flex-shrink: 0;
}

// Here you can add other styles
.btn-google {
  background-color: #ffffff;
  // box-shadow: 0 1px 2px 1px #ddd;
}
.btn-social {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: 0 1px 2px 1px #ddd;
}
.pointer-cursor {
  cursor: pointer;
}
.sidebar-nav {
  .nav-link {
    padding: 0.4rem 1rem;
    border-radius: 0px;
  }
  .nav-title {
    text-transform: none;
    font-size: 1.1rem;
    margin-top: 0.2rem;
  }
}
.media-box {
  height: 96px;
  width: 96px;
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  p {
    margin-bottom: 8px;
  }
  svg {
    margin-bottom: 8px;
  }
}

.bb-form {
  .form-label {
    margin-bottom: 2px;
  }
}

.bb-breadcrumb {
  top: calc(4rem + 2px) !important;
  .container-fluid {
    min-height: 3rem !important;
  }
  .breadcrumb-item {
    cursor: pointer;
  }
}

.line-clamp-2 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}

.dropdown-menu.show {
  z-index: 9999;
}
.height-100-percent {
  height: 100%;
}
.bb-time-picker {
  width: 100%;
  .MuiInputBase-root {
    width: 100%;
    border-radius: 0;
  }
  .MuiInputBase-input {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  }
  fieldset {
    border-color: #dbdfe6 !important;
  }
}
